import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
let requestOptions = {
  method: 'GET',
};
document.body.style.backgroundColor = "#ff0077";
document.body.style.color = "white";
document.documentElement.style.display = "table";
document.body.style.display = "table-cell";
document.body.style.verticalAlign = "middle";
ShowThePage();
function ShowThePage() {
  root.render(
    <React.StrictMode>
       <h1>BalasoreClock</h1>
       <p>Please wait while the data is being loaded...</p>
    </React.StrictMode>
);
  ShowTheClock();
}
function ShowTheClock() {
  fetch(`https://balasoreclockserver.namishkumar.in/`, requestOptions)
.then(response => response.json())
.then(result => showTime(result))
.catch(error => alert(error));
function showTime(result) {
  /*const sample = {
    "year": 2023,
  "month": 1,
  "day": 22,
  "hour": 11,
  "minute": 15,
  "seconds": 17,
  "milliSeconds": 228,
  "dateTime": "2023-01-22T11:15:17.2283566",
  "date": "01/22/2023",
  "time": "11:15",
  "timeZone": "Asia/Kolkata",
  "dayOfWeek": "Sunday",
  "dstActive": false
  }*/
  const year = result["year"];
  const month = result["month"];
  const day = result["day"];
  const hour = result["hour"];
  const minute = result["minute"];
  let minuter;
  if(String(minute).length === 1) {
     minuter = "0" + String(minute);
  }else{
     minuter = minute;
  }
  console.log(minuter);
  const seconds = result["seconds"];
  const time = result["time"];
  const dayOfWeek = result["dayOfWeek"];
  document.body.style.backgroundColor = "#ffffff";
document.body.style.color = "black";
document.documentElement.style.display = "block";
document.body.style.display = "block";
document.body.style.verticalAlign = "none";
console.log("Successfully fetched data from https://balasoreclockserver.namishkumar.in. The data is ",  result);
  root.render(
    <React.StrictMode>
       <div>
          <h1>BalasoreClock</h1>
          <p>Now, The time in <b>Balasore <i>city</i></b> is:-</p>
          <h1>{hour}:{minuter}</h1>
          <form onSubmit={function(e) {
            e.preventDefault();
            ShowThePage();
          }}>
          <button type='submit'>Reload</button>
          </form>
          <p>Timezone: <b>GMT +5:30</b></p>
          <div id="clockContainer">
        <div id="hour"></div>
        <div id="minute"></div>
        <div id="second" hidden></div>
    </div>
    <p>Day of Week: <b>{dayOfWeek}</b></p>
    <p>Date: <b>{day}/{month}/{year}</b></p>
    <h1>Tools</h1>
        <p><u>Time API</u></p>
        <p>To use this time API, make a GET request to balasoreclockserver.namishkumar.in</p>
       </div>
    </React.StrictMode>
  );
  //setInterval(() => {
    
   //document.getElementById("second").style.transform = `rotate(${sec_rotation}deg)`;
  /*}, 1000);*/
  setTimeout(function() {
    let hr_rotation = 30 * hour + minute / 2; //converting current time
    let min_rotation = 6 * minute;
    let sec_rotation = 6 * seconds;
    document.getElementById("hour").style.transform = `rotate(${hr_rotation}deg)`;
    document.getElementById("minute").style.transform = `rotate(${min_rotation}deg)`;
  }, 1)
}
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
